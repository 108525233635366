<template>
  <div class="row">
    <div class="col-12">
      <div class="card card-custom gutter-b example example-compact">
        <div
          class="card-header d-flex align-items-center justify-content-between"
        >
          <div class="card-title">
            <h3 class="card-label">Reklama Ruxsatnomalari Malumoti</h3>
          </div>
        </div>
        <div class="card-body">
          <v-container fluid>
            <v-row>
              <v-col cols="12" md="3" class="pl-0">
                <v-text-field
                  disabled
                  :value="advertPermitsDetails.truck_info"
                  label="Mashina raqami"
                  :loading="isLoading"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      disabled
                      :value="advertPermitsDetails.permitted | dateFilter"
                      label="Boshlanish sanasi"
                      prepend-inner-icon="event"
                      readonly
                      dense
                      v-bind="attrs"
                      outlined
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="permitted"
                    @input="menu2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      :value="advertPermitsDetails.expires | dateFilter"
                      disabled
                      label="Tugash sanasi"
                      prepend-inner-icon="event"
                      readonly
                      dense
                      v-bind="attrs"
                      v-on="on"
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expires"
                    @input="menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" class="pl-0">
                <v-text-field
                  :value="advertPermitsDetails.reg_number"
                  label="Reg raqami"
                  disabled
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pl-0"></v-col>
            </v-row>
          </v-container>
        </div>
      </div>
    </div>
  </div>
</template>
<style></style>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  created() {
    this.$store.dispatch('getAdvertPermitsDetails', this.$route.params.id)
  },
  computed: {
    advertPermitsDetails() {
      const data = this.$store.state.requests.advertPermitsDetails
      if (data.truck_info !== undefined)
        data.truck_info = data.truck_info.truck_number
      return data
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Reklama Ruxsatnomalari Malumoti' }
    ])
  }
}
</script>
